import { createApi } from '.';
import { mission } from './apiMapping';
import {
  GroupLinkResponse,
  ListResponse,
  UpdateParams,
  ActiveListParams,
  ActiveListResponse,
  PlanListResponse,
} from '@/types/mission';

export const GroupLink = createApi<unknown, GroupLinkResponse>(mission.groupLink.url);
export const List = createApi<unknown, ListResponse>(mission.list.url);
export const Update = createApi<UpdateParams, unknown>(mission.update.url);
export const ActiveList = createApi<ActiveListParams, ActiveListResponse>(mission.activeList.url);
export const PlanList = createApi<unknown, PlanListResponse>(mission.planList.url);
