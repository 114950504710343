import { ref } from 'vue';
import { defineStore } from 'pinia';
import { GetResponse } from '@/types/integral';
import { Get } from '@/apis/integral';

export const useIntegralStore = defineStore('integral', () => {
  const integralInfo = ref<GetResponse | null>();

  async function getIntegral() {
    const res = await Get({});
    if (res?.code === 200) {
      const { data } = res;
      integralInfo.value = data;
    } else {
      integralInfo.value = null;
    }
  }

  return {
    integralInfo,
    getIntegral,
  };
});
