import { ref } from 'vue';
import { defineStore } from 'pinia';
import { PlanListResponse, ActiveListResponse } from '@/types/mission';
import { PlanList, ActiveList } from '@/apis/mission';

export const useMissionStore = defineStore('mission', () => {
  const planList = ref<PlanListResponse['plans']>();
  const activeList = ref<ActiveListResponse['plans']>();

  async function getPlanList() {
    const res = await PlanList({});
    if (res?.code !== 200) return;
    planList.value = res?.data?.plans;
  }

  const getActiveList = async () => {
    const res = await ActiveList({});
    if (res?.code !== 200) return;
    activeList.value = res.data?.plans;
  };

  return {
    planList,
    activeList,
    getPlanList,
    getActiveList,
  };
});
