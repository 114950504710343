export { ConfigProvider } from 'vant';
import { Locale } from 'vant';
import { getCurLocale } from '@/config/region';
import vantLocale from './locale';

export const setLocale = async (item: any) => {
  const _vanLocale: any = vantLocale;
  const current = await (_vanLocale[item.language] || _vanLocale[getCurLocale().language])();
  Locale.use(current.default);
};
