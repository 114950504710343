import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getCurLocale } from '@/config/region';

interface Locale {
  name: string;
  language: string;
  region: string;
  timezone: string;
}

export const useSettingsStore = defineStore(
  'settings',
  () => {
    const GlobalImg = ref<string>();
    const pageTitle = ref<Record<string, string>>({});
    const LocaleInfo = ref<Locale>(getCurLocale());

    async function setPageTitle(name: string, title: string) {
      pageTitle.value[name] = title;
    }

    async function setGlobalImg(path: string) {
      GlobalImg.value = path;
    }

    async function setLocale(info: Locale) {
      LocaleInfo.value = info;
    }

    return {
      pageTitle,
      GlobalImg,
      LocaleInfo,
      setPageTitle,
      setGlobalImg,
      setLocale,
    };
  },
  {
    persist: {
      key: 'Settings',
      pick: ['GlobalImg', 'LocaleInfo'],
    },
  }
);
