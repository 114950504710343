import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import { NavigationGuard, NavigationHookAfter } from 'vue-router';
import { useLogin } from '@/store/modules/login';

NProgress.configure({ showSpinner: false });

interface NaviGuard {
  before: NavigationGuard;
  after: NavigationHookAfter;
}

const loginGuard: NavigationGuard = (to) => {
  if (!useLogin().token && to.meta.isAuth) {
    return `/login?redirect=${to.fullPath}`;
  }
};

const ProgressGuard: NaviGuard = {
  before: () => {
    NProgress.start();
  },
  after: () => {
    NProgress.done();
  },
};

export default {
  before: [ProgressGuard.before, loginGuard],
  after: [ProgressGuard.after],
};
