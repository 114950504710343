import { createApi } from '.';
import { playlet } from './apiMapping';
import {
  GroupListParams,
  GroupListResponse,
  GroupInListParams,
  GroupInListResponse,
  LedgerListParams,
  LedgerListResponse,
  LedgerUpdateParams,
  EpisodeListParams,
  EpisodeListResponse,
  SearchListParams,
  SearchListResponse,
  VideoAuthParams,
  VideoAuthResponse,
  CollectAddParams,
  CollectUpdateParams, CollectUpdateResponse,
  CollectListParams, CollectListResponse,
  MoreListParams,
  MoreListResponse,
} from '@/types/playlet';

export const GroupList = createApi<GroupListParams, GroupListResponse>(playlet.groupList.url);
export const GroupInList = createApi<GroupInListParams, GroupInListResponse>(playlet.groupInList.url);
export const LedgerList = createApi<LedgerListParams, LedgerListResponse>(playlet.ledgerList.url);
export const LedgerUpdate = createApi<LedgerUpdateParams, unknown>(playlet.ledgerUpdate.url);
export const EpisodeList = createApi<EpisodeListParams, EpisodeListResponse>(playlet.episodeList.url);
export const SearchList = createApi<SearchListParams, SearchListResponse>(playlet.searchList.url);
export const VideoAuth = createApi<VideoAuthParams, VideoAuthResponse>(playlet.videoAuth.url);
export const CollectAdd = createApi<CollectAddParams, unknown>(playlet.collectAdd.url);
export const CollectUpdate = createApi<CollectUpdateParams, CollectUpdateResponse>(playlet.collectUpdate.url);
export const CollectList = createApi<CollectListParams, CollectListResponse>(playlet.collectList.url);
export const MoreList = createApi<MoreListParams, MoreListResponse>(playlet.moreList.url);
