import { Method } from '@/utils/axiosHttp';
import http from '@/plugins/https';
import { Response } from '@/types';
import * as apiMapping from './apiMapping';

function generateApiComparison(mapping: Record<string, Record<string, { url?: string; title?: string }>>) {
  return new Map(
    Object.values(mapping).flatMap((category) =>
      Object.values(category)
        .filter((item) => item.url)
        .map((item) => [item.url, item])
    )
  );
}

export const ApiComparison = generateApiComparison(apiMapping);

export function createApi<T, R>(url: string, method: Method = 'post_json') {
  return (data: T, config?: any) => http.request<R, Response<R>>(url, method, data, config);
}

// The values replace '[X]' in the 'title' field of apiMapping
export const ApisTitleList: Record<string, string> = {
  TYPE_ADMIN: '管理员',
  TYPE_PARTNER: '代理商',
  TYPE_SALESMAN: '业务员',
  TYPE_SYSTEM: '平台银行账户',
  TYPE_USER: '用户银行账户',
};
