<script setup lang="ts">
  import { ref, watch, defineAsyncComponent } from 'vue';
  import { useRoute } from 'vue-router';
  import VConsole from '@/plugins/vconsole';

  const AppHeader = defineAsyncComponent(() => import('@/components/AppHeader.vue'));
  const AppFooter = defineAsyncComponent(() => import('@/components/AppFooter'));
  const CouponDialog = defineAsyncComponent(() => import('@/components/Dialog/coupon.vue'));

  // const { locale, t: $i18nt } = useI18n();
  const route = useRoute();
  const { meta } = route;
  const addHeader = ref<boolean>(meta.addHeader as boolean);
  const addFooter = ref<boolean>(meta.addFooter as boolean);
  const bgInfo: any = {
    base: 'bg-[url("@/assets/images/common/bg_app_01.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_-106px]',
    login: 'bg-[url("@/assets/images/common/bg_app_02.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_0]',
    mission: 'bg-[url("@/assets/images/common/bg_app_03.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_0]',
  };

  watch(route, (toRoute) => {
    const {
      meta: { hasHeader, hasFooter },
    } = toRoute;
    addHeader.value = !!hasHeader;
    addFooter.value = !!hasFooter;
    setVConsole();
  });

  // vconsole手机调试工具
  const vConsole = ref<any>(null);
  const setVConsole = () => {
    if (route.query.debug && !vConsole.value) {
      vConsole.value = VConsole();
    }
  };
</script>

<template>
  <suspense>
    <van-config-provider
      theme="dark"
      class="overflow-hidden text-[12px] text-white"
      :class="bgInfo[route?.meta?.bgClass as string]">
      <AppHeader v-if="addHeader && !route?.query?.hiddenNav" />
      <router-view v-slot="{ Component }" :class="[addFooter ? 'pb-[68px]' : '']">
        <keep-alive>
          <component :is="Component" v-if="route.meta.keepAlive" :key="route.name" />
        </keep-alive>
        <component :is="Component" v-if="!route.meta.keepAlive" :key="route.name" />
      </router-view>
      <CouponDialog v-if="route.name !== 'delete'" />
      <AppFooter v-if="addFooter" />
    </van-config-provider>
  </suspense>
  <!-- 开启底部安全区适配 -->
  <!-- <van-number-keyboard safe-area-inset-bottom /> -->
</template>

<style scoped lang="less"></style>
