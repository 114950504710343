import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useCouponsStore = defineStore(
  'coupons',
  () => {
    const isOpen = ref<boolean>(false);
    const showPeriod = ref<number>();
    const Period = computed(() => showPeriod.value);
    const ChooseCoupon = ref<any>();
    const chooseCoupon = computed(() => ChooseCoupon.value);

    async function setOpenType(type: boolean, period?: number) {
      isOpen.value = type;
			showPeriod.value = period;
    }

    async function setCoupon(item: any) {
      ChooseCoupon.value = item;
    }

    return {
      isOpen,
      Period,
			showPeriod,
      chooseCoupon,
      ChooseCoupon,
      setOpenType,
      setCoupon,
    };
  },
  {
    persist: {
      key: 'Coupons',
      pick: ['ChooseCoupon'],
    },
  }
);
