import { createApi } from '.';
import { integral } from './apiMapping';
import {
  EstimateGetResponse,
  GetResponse,
  LedgerParams,
  LedgerResponse,
  FrozenParams,
  FrozenResponse,
  TransferParams,
} from '@/types/integral';

export const EstimateGet = createApi<unknown, EstimateGetResponse>(integral.estimateGet.url);
export const Get = createApi<unknown, GetResponse>(integral.get.url);
export const Ledger = createApi<LedgerParams, LedgerResponse>(integral.ledger.url);
export const Frozen = createApi<FrozenParams, FrozenResponse>(integral.frozen.url);
export const Transfer = createApi<TransferParams, unknown>(integral.transfer.url);

