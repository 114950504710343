import Dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

Dayjs.extend(utc);
Dayjs.extend(timezone);

export const setDayDefault = (current: { timezone: string; name: string }) => {
  Dayjs.tz.setDefault(current.timezone);
  // dayjs() // 依旧是服务器/本地时区
  // dayjs.tz() // 这样才是指定的时区
};
