import { defineStore } from 'pinia';
import { Create, Pay } from '@/apis/orders';
import { CreateParams, PayParams } from '@/types/orders';

export const useOrdersStore = defineStore('orders', () => {
  async function createOrder(params: CreateParams) {
    return await Create(params);
  }

  async function payOrder(params: PayParams) {
    return await Pay(params);
  }

  return {
    createOrder,
    payOrder,
  };
});
