import Dayjs from 'dayjs';

// 格式化时间
export const formatTime = (time: number | string, format = 'MM/DD/YYYY HH:mm:ss', defaultValue = '-'): string => {
  const timestamp = typeof time === 'string' ? parseInt(time, 10) : time;
  return timestamp > 0 ? Dayjs(timestamp * 1000).format(format) : defaultValue;
};

/**
 * 邮箱脱敏
 * Example: test@example.com -> tes***@example.com
 * @param email - Email
 * @returns Masked email address or original string if invalid email
 */
export const maskEmail = (email: string): string => {
  if (!email || typeof email !== 'string') return '-';

  const emailRegex = /^([^@\s]+)@([\w.-]+)$/;
  const match = email.match(emailRegex);

  if (!match) return email;

  const [, local, domain] = match;
  const maskedLocal = local.slice(0, 3) + '*'.repeat(Math.max(local.length - 3, 1));

  return `${maskedLocal}@${domain}`;
};

/**
 * 手机脱敏
 * Example: 18800000000 -> 18****000
 * @param mobile - mobile
 * @returns Masked mobile number or original string if invalid mobile
 */
export const maskMobile = (mobile: string): string => {
  if (!mobile || typeof mobile !== 'string') return '-';

  const len = mobile.length;
  const maskedMobile = mobile.slice(0, 2) + '****' + mobile.slice(len - 3);

  return maskedMobile;
};
