import { createPinia } from 'pinia';
export { storeToRefs } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

function getModules() {
  const modulesFiles = import.meta.glob('./modules/*.ts', { eager: true });
  const modules: any = {};

  Object.entries(modulesFiles)?.forEach(([key, value]: any) => {
    const fileName = key.replace(/^\.\/|\.ts$/g, '');

    Object.entries(value).forEach(([inKey, inItem]) => {
      const newKey = inKey in modules
        ? `${inKey}${fileName.charAt(0).toUpperCase()}${fileName.slice(1).toLowerCase()}`
        : inKey;

      modules[newKey] = inItem;
    });
  });

  return modules;
}

const modules: any = getModules();
export { modules };

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
export default pinia;
