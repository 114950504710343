import langData from './languages.json';

interface LanguageItem {
  name: string;
  language: string;
  region: string;
  timezone: string;
  unit: string;
}

export const LocaleList: Record<string, LanguageItem> = {
  // 印尼文
  id: {
    name: 'Bahasa Indonesia',
    language: 'ind',
    region: 'id',
    timezone: 'Asia/Bangkok',
    unit: 'US $',
  },
  // 英语
  us: {
    name: 'English',
    language: 'en',
    region: 'us',
    timezone: 'America/New_York',
    unit: 'US $',
  },
  // 简体中文
  cn: {
    name: '简体中文',
    language: 'zh',
    region: 'cn',
    timezone: 'Asia/Shanghai',
    unit: 'US $',
  },
  // 日文
  jp: {
    name: '日本語（Nihongo）',
    language: 'ja',
    region: 'jp',
    timezone: 'Asia/Tokyo',
    unit: 'US $',
  },
  // 韩文
  kr: {
    name: '한국어（Hanguk-eo）',
    language: 'ko',
    region: 'kr',
    timezone: 'Asia/Seoul',
    unit: 'US $',
  },
};

// 当前语言环境
const localSet = window?.localStorage?.getItem('Settings') ?? '';
export const getCurLocale = () => ({...(localSet
  ? LocaleList[JSON.parse(localSet)?.LocaleInfo?.region]
  : LocaleList[__APP_CURRENCY__.toLocaleLowerCase()] || LocaleList.id)});
  // 当前语言集合
export const getLanguageGroup = () => {
  const _lang: any = {};
  Object.values(LocaleList)?.map((item) => {
    _lang[item.language] = (langData as any)?.[item.language] ?? {};
  });
  return _lang;
};
