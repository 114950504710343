import { ref } from 'vue';
import { defineStore } from 'pinia';
import { showSuccessToast } from 'vant';
import {
  GroupListParams,
  GroupListResponse,
  CollectStatus,
  LedgerListParams,
  EpisodeListParams,
} from '@/types/playlet';
import { GroupList, CollectUpdate, LedgerList, EpisodeList } from '@/apis/playlet';

export const usePlayletStore = defineStore('playlet', () => {
  const homeGroupList = ref<GroupListResponse['groups']>();
  const recommendGroupList = ref<GroupListResponse['groups']>();
  const groupTab = ref<{ id: string; key: string }>();

  async function getPlayGroupList(params: GroupListParams) {
    const res = await GroupList(params);
    if (res?.code !== 200) return;
    if (params?.page === 'TYPE_HOME') {
      homeGroupList.value = res?.data?.groups;
    } else {
      recommendGroupList.value = res?.data?.groups;
    }
  }

  async function setCollectUpdate(ids: string[], status: CollectStatus = 'COLLECT_STATUS_COLLECTING') {
    const res = await CollectUpdate({ ids, status });
    if (res?.code !== 200) return;
    showSuccessToast(status === 'COLLECT_STATUS_COLLECTING' ? '收藏成功' : '取消收藏成功');
    return true;
  }

  async function getLedgerList(params: LedgerListParams) {
    return await LedgerList(params);
  }

  async function getEpisodeList(params: EpisodeListParams) {
    return await EpisodeList(params);
  }

  async function setGroupTab(info: { id: string; key: string }) {
    groupTab.value = info;
  }

  return {
    homeGroupList,
    recommendGroupList,
    groupTab,
    getPlayGroupList,
    setCollectUpdate,
    getLedgerList,
    getEpisodeList,
    setGroupTab,
  };
});
