import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineStore } from 'pinia';
import { showToast } from 'vant';
import { TokenGetType, TokenGetResponse, MessagesSendParams } from '@/types/security';
import { MessagesSend, TokenGet } from '@/apis/security';
import i18n from '@/plugins/i18n';

interface ForgotAuth {
  code: string;
  email?: string;
  mobile?: string;
}

export const useSecurityStore = defineStore('security', () => {
  const $i18nt = i18n.global.t;
  const router = useRouter();
  const tokenVerify = ref<TokenGetResponse>();
  const forgotAuth = ref<ForgotAuth>();

  async function messagesSend(params: MessagesSendParams) {
    return await MessagesSend(params);
  }

  async function getTokenVerify(type: TokenGetType) {
    const res = await TokenGet({ type });
    if (res?.code !== 200) {
      showToast({
        type: 'fail',
        message: $i18nt('Common.Failed'),
      });
      router.back();
      return;
    }
    tokenVerify.value = res?.data;
  }

  function setForgotAuth(auth: ForgotAuth) {
    forgotAuth.value = auth;
  }

  return {
    tokenVerify,
    forgotAuth,
    getTokenVerify,
    messagesSend,
    setForgotAuth,
  };
});
