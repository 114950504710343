import Dayjs from 'dayjs';

// 正则表达式
export const RulesPattern = {
  isInt: /^\d+$/,
  isNumberFloat: /^\d+(\.\d+)?$/,
  isNumber0_100: /^([1-9]?[0-9]|100)$/,
  isEmail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  isLNC: (min: number, max: number) => new RegExp(`^(?=.*[a-zA-Z])(?=.*\\d)[a-zA-Z\\d]{${min},${max}}$`),
  isPassword: (min: number, max: number) =>
    new RegExp(`^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{${min},${max}}$`), // 数字字母特殊符号
};

// 复制文字到剪贴板
export function copyToClipboard(str: string): boolean {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str).catch((err) => console.error('Failed to copy: ', err));
    return true;
  }

  const textArea = document.createElement('textarea');
  textArea.value = str;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand('copy');
    return true;
  } catch (err) {
    console.error('Failed to copy: ', err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

// 生成随机密码
export function generatePassword(length = 8): string {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => charset[x % charset.length])
    .join('');
}

// 生成随机手机号
export function generateMobileNumber(): string {
  const prefixes = ['130', '131', '132', '133', '135', '137', '138', '170', '187', '189'];
  const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
  const suffix = Math.floor(Math.random() * 100000000)
    .toString()
    .padStart(8, '0');
  return prefix + suffix;
}

// 获取本地图片URL
export const getAssetUrl = (url: string, folders: string = 'images') =>
  new URL(`../assets/${folders}/${url}`, import.meta.url).href;

export const getFullUrl = (url: string) => {
  if (!url) return '';
  return __APP_STATIC_URL__ ? `${__APP_STATIC_URL__}/${url}` : `${window?.location?.origin}/static/${url}`;
};

/**
 * 获取文件的Base64编码
 * @param file - 要编码的文件
 * @returns Promise<string> - 返回Base64编码的字符串
 */
export function getFileBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const isMobile = (() =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))();

export const isIos = (() => /iPhone|iPad|iPod|iOS/i.test(navigator.userAgent))();

export const isAndroid = (() => /Android/i.test(navigator.userAgent))();

export const isXiaomi = (() =>
  /mi\s/i.test(navigator.userAgent) ||
  /mix\s/i.test(navigator.userAgent) ||
  /redmi/i.test(navigator.userAgent) ||
  /xiaomi/i.test(navigator.userAgent))();

export const diffTime = {
  day: (time: string, space: number = 7) => {
    const curTime = Dayjs().tz().unix();
    return (curTime - Number(time)) / (60 * 60 * 24) < space;
  },
};

// 打开外部url
export const openHref = (href: string) => {
  const eleLink = document.createElement('a');
  //设置链接
  eleLink.setAttribute('href', href);
  //新窗口打开链接
  eleLink.setAttribute('target', '_blank');
  //设置标签ID
  eleLink.setAttribute('id', 'reportpoint');

  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
};
