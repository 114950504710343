import CryptoJS from 'crypto-js';
import { generatePassword } from '@/utils/tools';

const KEY_POSITION = 2;
const KEY_LEN = 8;

export const encryptData = (data: string, secretKey: string = generatePassword(KEY_LEN)): string => {
  const encryptedStr = CryptoJS.AES.encrypt(data, secretKey).toString();
  return encryptedStr.slice(0, -KEY_POSITION) + secretKey + encryptedStr.slice(-KEY_POSITION);
};

export const decryptData = (encryptedData: string, keyLength: number = KEY_LEN): string => {
  const dataLength = encryptedData.length;
  const secretKeyStart = dataLength - (keyLength + KEY_POSITION);
  const encryptedStr = encryptedData.slice(0, secretKeyStart) + encryptedData.slice(-KEY_POSITION);
  const secretKey = encryptedData.slice(secretKeyStart, -KEY_POSITION);

  try {
    return CryptoJS.AES.decrypt(encryptedStr, secretKey).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Decryption failed:', error);
    return '';
  }
};
