import { createApi } from '.';
import { security } from './apiMapping';
import {
  UploadResponse,
  CertificationsGetResponse,
  CertificationsSubmitParams,
  TokenGetParams,
  TokenGetResponse,
  CodesSendParams,
  TokenCheckParams,
  MessagesSendParams,
  ForgetResetParams,
} from '@/types/security';

export const Upload = createApi<FormData, UploadResponse>(security.upload.url);
export const CertificationsGet = createApi<unknown, CertificationsGetResponse>(security.certificationsGet.url);
export const CertificationsSubmit = createApi<CertificationsSubmitParams, unknown>(security.certificationsSubmit.url);
export const UsersDelete = createApi<unknown, unknown>(security.usersDelete.url);
export const MessagesCheck = createApi<unknown, unknown>(security.messagesCheck.url);
export const TokenGet = createApi<TokenGetParams, TokenGetResponse>(security.tokenGet.url);
export const TokenCheck = createApi<TokenCheckParams, unknown>(security.tokenCheck.url);
export const CodesSend = createApi<CodesSendParams, unknown>(security.codesSend.url);
export const MessagesSend = createApi<MessagesSendParams, unknown>(security.messagesSend.url);
export const ForgetReset = createApi<ForgetResetParams, unknown>(security.forgetReset.url);