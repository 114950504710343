import { createApi } from '.';
import { login } from './apiMapping';
import {
  RegisterParams,
  LoginParams,
  LoginResponse,
} from '@/types/login';

export const Register = createApi<RegisterParams, LoginResponse>(login.register.url);
export const Login = createApi<LoginParams, LoginResponse>(login.login.url);
export const Logout = createApi<unknown, unknown>(login.logout.url);
export const ThirdAccountListout = createApi<unknown, unknown>(login.thirdAccountList.url);
export const ThirdLoginListout = createApi<unknown, unknown>(login.thirdLoginList.url);
export const TiktokLoginCallback = createApi<unknown, unknown>(login.tiktokLoginCallback.url);

